import { memo, useEffect, useState } from "react";
import useOrangeHeader from "app/utils/hooks/useOrangeHeader";
import "./Header.scss";
import MainHeader from "app/pages/Header/Header";
import { useFlagship, useFsFlag } from "@flagship.io/react-sdk";
import axios from "axios";

const Header = props => {
	const { status: fsStatus } = useFlagship();
	const themeFlag = useFsFlag("header_orange_use_custom", undefined);
	const useHeaderOrange = themeFlag.getValue();

	const [menu, setMenu] = useState();

	useOrangeHeader({ display: useHeaderOrange === true });

	useEffect(() => {
		if (useHeaderOrange === true) {
			axios
				.get(
					"https://tendances.orange.fr/esi/menu.html?absolutePath=1&includeAssetsRootSize=1"
				)
				.then(response => {
					setMenu(response.data);
				});
		}
	}, [useHeaderOrange]);

	return (
		<div className="header-orange">
			<header id="o-header" />
			{fsStatus.isSdkReady && !useHeaderOrange && <MainHeader {...props} />}
			{useHeaderOrange === true && <div dangerouslySetInnerHTML={{ __html: menu }} />}
		</div>
	);
};

export default memo(Header);
