import Footer from "app/pages/.shared/Footer/Footer";
import { useContext } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import "./Footer.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

export default props => {
	// widget-id permet de definir les formats de pubs par le partenaire
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;
	// const { pathname } = useLocation();

	const id = isMobile
		? "ora_1m_1x1_chaine.hp.lienscommerciaux"
		: "oan_ora_1_1x1_chaine.hp.lienscommerciaux";

	const widgetId = isMobile ? "CRMB_11" : "SF_11";
	// const shouldShowOrangeFooter = ![
	// 	"/booking/quote",
	// 	"/booking/payment",
	// 	"/booking/confirmation",
	// ].some(url => pathname.includes(url));

	return (
		<>
			<div className="footer__liens-commerciaux">
				<Typography variant={TYPOGRAPHY_VARIANTS.XL2} isBold component="h3">
					Liens commerciaux
				</Typography>
				<div id={id} widget-id={widgetId} />
			</div>
			<Footer {...props} />
			{/*{shouldShowOrangeFooter && <div id="o-footer" />}*/}
		</>
	);
};
